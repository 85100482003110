<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Tỉnh/Thành phố</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-form-input
            v-model="searchTerm"
            class=""
            placeholder="Tìm kiếm..."
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <vue-good-table
            ref="mytable"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            @on-selected-rows-change="selectionChanged"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Common -->
              <span>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Hiển thị tối đa </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '10']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    trên tổng số {{ props.total }} kết quả
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
            <div slot="emptystate">
              <div class="text-center text-muted">
                Không có bản ghi nào!
              </div>
            </div>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BPagination,
  BTooltip,
  VBTooltip,
  BFormSelect,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import store from '@/store/index';
import { VueGoodTable } from 'vue-good-table';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BTooltip,
    BFormSelect,

    // eslint-disable-next-line vue/no-unused-components
    VBTooltip,

    VueGoodTable,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      //
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Mã',
          field: 'id',
        },
        {
          label: 'Tên',
          field: 'name',
        },
      ],
      rows: [],
      // filter
      searchTerm: '',
      active: null,
      // current selected rows
      selectedRows: [],
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    active(val) {

      // this.$refs.mytable.filterRows(this.serverParams.columnFilters, true);

      // this.$refs.mytable.$children[1].updateFiltersImmediately({
      //   field: "active",
      // });
    },
  },
  created() {
    // this.$http.get("/resident/apartments").then((res) => {
    //   this.rows = res.data;
    // });
    useJwt.getProvince().then(res => {
      this.rows = res.data;
    });
  },
  methods: {
    selectionChanged(params) {
      this.selectedRows = params.selectedRows;
    },
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
